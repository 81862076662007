import { thunk, Thunk, thunkOn, ThunkOn } from 'easy-peasy';
import debounce from 'lodash/debounce';
import type { StoreModel } from '@customer/state/store/model';
import type { AccountModel } from './model';
import accountInfo from './info';
import coveCreditBalance from './coveCreditBalance';
import coveCreditBalanceHistory from './coveCreditBalanceHistory';
import equipmentBalance from './equipmentBalance';
import monitoringPlan from './monitoringPlan';
import referralCode from './referralCode';
import referrals from './referrals';
import amountDue from './amountDue';

export type Token = Promise<string> | null;

export interface AccountModelRoot {
  getToken: Thunk<AccountModel, undefined, any, StoreModel>;
  reset: Thunk<AccountModel, undefined, any, StoreModel>;
  onStoreReset: ThunkOn<AccountModel, any, StoreModel>;
}

const accountRoot: AccountModelRoot = {
  getToken: thunk(
    debounce(
      async (actions, payload, helpers) => {
        const storeActions = helpers.getStoreActions();
        return await storeActions.pando.getToken();
      },
      500,
      { leading: true, trailing: false }
    )
  ),
  reset: thunk(async (actions, payload, helpers) => {}),
  onStoreReset: thunkOn(
    (actions, storeActions) => storeActions.reset,
    async (actions, target, helpers) => {
      actions.reset();
    }
  ),
};

const account: AccountModel = {
  ...accountRoot,
  accountInfo,
  coveCreditBalance,
  coveCreditBalanceHistory,
  equipmentBalance,
  monitoringPlan,
  referralCode,
  referrals,
  amountDue,
};

export default account;
