import service, {
  PandoResponse,
} from '@customer/services/pandoService/service';

const deletePaymentMethod = async (
  {
    accountGuid,
    paymentInfoId
  }: {
    accountGuid: string;
    paymentInfoId: number;
  },
  { accessToken }: { accessToken: string }
) => {
  const response = service.delete<PandoResponse<{}>>(
    `/api/deletePaymentMethod`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        AccountGuid: accountGuid,
        PaymentInfoId: paymentInfoId
      },
    }
  );
  return response;
};

export default deletePaymentMethod;
