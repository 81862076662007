import { Script } from 'gatsby';
import GTM from './GTM';

const Scripts = ({ env }) => {
  const friendBuyScript = `
    if (!window._fbInitialized) {
      window._fbInitialized = true;
      window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];

      if (!friendbuyAPI.merchantId) {  // Only set if not already set
        friendbuyAPI.merchantId = "65cf869f-1fea-46b9-b80e-fa97dcc5b790";
        friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);

        (function(f, r, n, d, b, u, y) {
          while ((u = n.shift())) {
            (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
            b.async = 1;
            b.src = u;
            y.parentNode.insertBefore(b, y);
          }
        })(document, "script", [
          "https://static.fbot.me/friendbuy.js",
          "https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
        ]);
      }
    }
  `;

  return (
    <>
      <Script
        type={'text/javascript'}
        defer
        dangerouslySetInnerHTML={{
          __html: GTM,
        }}
        onError={() => {
          // eslint-disable-next-line no-console
          console.error(`failure loading GTM`);
        }}
      />
      <Script
        key="friendBuy"
        type={'text/javascript'}
        onLoad={() => console.log(`success loading friendBuy`)}
        onError={() => console.log(`failure loading friendBuy`)}
        dangerouslySetInnerHTML={{ __html: friendBuyScript }}
      />
      <Script
        type={'text/javascript'}
        key="tidioChatWidget"
        async
        src="https://code.tidio.co/d9thk8ahb9shoozzewtnbjcgecm3mil7.js"
        onError={() => console.log(`failure loading tidioChatApi`)}
      />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PSNPXVM"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          title="gtm"
        ></iframe>
      </noscript>

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="anonymous"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        rel="stylesheet"
      />
      {/* Kill the friendbuy ribbon that's showing up on staging */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
          let int${Date.now()} = setInterval(() => {
            const friendBuyRibbon = document.querySelector("[id*='fbt-ribbon']");
            if(friendBuyRibbon){
              friendBuyRibbon.remove();
              clearInterval(int${Date.now()})
            }
          }, 1000)
        `,
        }}
      ></script>
    </>
  );
};

export default Scripts;
