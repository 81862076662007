import service from '../service';

export interface AmountDue {
  invoices: {
    id: number;
    dueDate: string;
    periodStartDate: string;
    periodEndDate: string;
    amountDue: number;
    total: number;
    tax: number;
    creditCardFee?: number;
  }[];

  aging: {
    total: number;
  };
}

const getAmountDue = async (
  { accountGuid }: { accountGuid: string },
  { accessToken }: { accessToken: string }
) => {
  const response = service.get<AmountDue>(
    `/account/${accountGuid}/amount-due`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default getAmountDue;
