import service from '@customer/services/coveService/service';

export interface PlaceOneTimePaymentParams {
  amount: number;
  billingAddress: {
    address1: string;
    city: string;
    state: string;
    postcode: string;
  };
  payment: {
    paymentProvider: string;
    name: string;
    encryptedNumber: string;
    encryptedCvv: string;
    expirationDate: string;
  };
  setAsDefaultPaymentMethod: boolean;
}

const placeOneTimePayment = async (
  {
    accountGuid,
    ...params
  }: { accountGuid: string } & PlaceOneTimePaymentParams,
  { accessToken }: { accessToken: string }
) => {
  const response = service.post(
    `/account/${accountGuid}/one-time-payment`,
    {
      ...params,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  return response;
};

export default placeOneTimePayment;
